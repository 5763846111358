<template>
  <div id="priceList">
    <section
      class="page-header"
      style="background-image: url('/imgs/price-list-01.png')"
    >
      <div class="caption">
        <h4>{{ $lang("title") }}</h4>
        <i class="isologo"></i>
        <h1>{{ $lang("subtitle") }}</h1>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="card card-medium-tint p-4">
          <div class="card-body">
            <i class="isologo isologo-tertiary mb-3"></i>
            <h4 class="text-dark mb-4">{{ $lang("desc.title") }}</h4>
            <p
              class="text-dark-tint fw-500"
              v-html="$lang('desc.description')"
            />
            <p class="text-dark-tint fw-800 mb-0">{{ $lang("desc.tip") }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-5">
      <div class="container pb-3 table-responsive">
        <table class="table-primary">
          <tr>
            <th>{{ $lang("table.weeks") }}</th>
            <th>
              {{ $lang("table.accelerated") }} <br />
              <small>(20h/{{ $lang("table.week") }})</small>
            </th>
            <th>
              {{ $lang("table.semi_intensive") }} <br />
              <small>(10h/{{ $lang("table.week") }})</small>
            </th>
            <th>
              {{ $lang("table.extensive") }} <br />
              <small>(4h/{{ $lang("table.week") }})</small>
            </th>
          </tr>
          <tr v-for="p of prices" :key="p.weeks">
            <td class="bg-primary-50 text-white fw-600">{{ p.weeks }}</td>
            <td>€ {{ p.a }}</td>
            <td>€ {{ p.b }}</td>
            <td>€ {{ p.c }}</td>
          </tr>
        </table>
      </div>
    </section>

    <section class="pb-6">
      <div class="container">
        <div class="card card-medium-tint p-4">
          <div class="card-body">
            <i class="isologo isologo-tertiary mb-3"></i>
            <h4 class="text-dark mb-4">{{ $lang("private.title") }}</h4>
            <p
              class="text-dark-tint fw-500 mb-0"
              v-html="$lang('private.description')"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="pb-5">
      <div class="container pb-3 table-responsive">
        <table class="table-primary no-wrap">
          <tr>
            <th colspan="5">{{ $lang("table2.title") }}</th>
          </tr>
          <tr>
            <td class="bg-primary-50 text-white fw-600">
              {{ $lang("table2.hours_pack") }}
            </td>
            <td class="bg-primary-50 text-white fw-600">
              {{ $lang("table2.hours_a") }}
            </td>
            <td class="bg-primary-50 text-white fw-600">
              {{ $lang("table2.hours_b") }}
            </td>
            <td class="bg-primary-50 text-white fw-600">
              {{ $lang("table2.hours_c") }}
            </td>
            <td class="bg-primary-50 text-white fw-600">
              {{ $lang("table2.hours_d") }}
            </td>
          </tr>
          <tr v-for="p of prices_private" :key="p.hours">
            <td class="bg-primary-50 text-white fw-600">{{ p.hours }}</td>
            <td>€ {{ p.a }}</td>
            <td>€ {{ p.b }}</td>
            <td>€ {{ p.c }}</td>
            <td>{{ $lang("table2.add") }} € {{ p.d }}</td>
          </tr>
        </table>
      </div>
    </section>

    <section class="pb-6">
      <div class="container">
        <div class="card card-medium-tint p-4">
          <div class="card-body">
            <ul class="disc-style text-dark-tint fw-500 mb-0 disc-primary">
              <li>{{ $lang("private.items.1") }}</li>
              <li>{{ $lang("private.items.2") }}</li>
              <li>{{ $lang("private.items.3") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-6">
      <div class="container">
        <div class="text-center">
          <i class="isologo isologo-tertiary mb-3"></i>
          <h4 class="text-dark mb-5">{{ $lang("special_packages.title") }}</h4>
        </div>

        <!-- <div class="card card-medium-tint p-4 fw-500">
          <div class="card-body">
            <i class="isologo isologo-tertiary mb-3"></i>
            <p
              class="text-dark-tint"
              v-html="$lang('special_packages.description')"
            />

            <ul class="disc-style text-dark-tint mb-3 disc-primary">
              <li>{{ $lang("special_packages.items.1") }}</li>
              <li>{{ $lang("special_packages.items.2") }}</li>
              <li>{{ $lang("special_packages.items.3") }}</li>
              <li>{{ $lang("special_packages.items.4") }}</li>
              <li>{{ $lang("special_packages.items.5") }}</li>
              <li>{{ $lang("special_packages.items.6") }}</li>
              <li>{{ $lang("special_packages.items.7") }}</li>
            </ul>

            <p class="text-dark-tint mb-0">
              {{ $lang("special_packages.description_2") }}
            </p>
          </div>
        </div> -->
      </div>
    </section>

    <!-- <section class="pb-5">
      <div class="container pb-3 table-responsive">
        <table class="table-tertiary no-wrap even">
          <tr>
            <th colspan="4">{{ $lang("long_term.title") }}</th>
          </tr>
          <tr>
            <td
              class="bg-tertiary-50 text-white fw-600"
              colspan="4"
              style="white-space: unset"
            >
              {{ $lang("long_term.subtitle") }}
            </td>
          </tr>
          <tr>
            <td class="bg-tertiary-50 fw-600 text-white">
              {{ $lang("long_term.weeks") }}
            </td>
            <td class="bg-tertiary-50 fw-600 text-white">
              {{ $lang("long_term.holidays") }}
            </td>
            <td class="bg-tertiary-50 fw-600 text-white">
              {{ $lang("long_term.months") }}
            </td>
            <td class="bg-tertiary-50 fw-600 text-white">
              {{ $lang("long_term.price") }}
            </td>
          </tr>
          <tr v-for="p of long_term_prices" :key="p.weeks">
            <td>{{ p.weeks }} {{ $lang("long_term.weeks") }}</td>
            <td>{{ p.holidays }} {{ $lang("long_term.weeks") }}</td>
            <td>{{ p.months }} {{ $lang("long_term.months") }}</td>
            <td>€ {{ p.price }}</td>
          </tr>
        </table>
      </div>
    </section> -->

    <section class="pb-6">
      <div class="container">
        <div class="card card-medium-tint p-4 fw-500">
          <div class="card-body">
            <i class="isologo isologo-tertiary mb-3"></i>
            <h4 class="text-dark mb-5">{{ $lang("dele_exam.title") }}</h4>

            <p
              class="text-dark-tint mb-0"
              v-html="$lang('dele_exam.description')"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="pb-5">
      <div class="container pb-3 table-responsive">
        <table class="table-tertiary no-wrap even">
          <tr>
            <th colspan="3">{{ $lang("dele_table.title") }}</th>
          </tr>
          <tr>
            <td class="bg-tertiary-50 text-white fw-600" colspan="3">
              {{ $lang("dele_table.subtitle") }}
            </td>
          </tr>
          <tr>
            <td class="bg-tertiary-50 fw-600 text-white">
              2 {{ $lang("dele_table.weeks") }}
            </td>
            <td class="bg-tertiary-50 fw-600 text-white">
              3 {{ $lang("dele_table.weeks") }}
            </td>
            <td class="bg-tertiary-50 fw-600 text-white">
              4 {{ $lang("dele_table.weeks") }}
            </td>
          </tr>
          <tr v-for="p of dele_prices" :key="p.a">
            <td>€ {{ p.a }}</td>
            <td>€ {{ p.b }}</td>
            <td>€ {{ p.c }}</td>
          </tr>
        </table>
      </div>
    </section>

    <section class="pb-6">
      <div class="container text-center">
        <i class="isologo isologo-tertiary mb-3"></i>
        <h4 class="text-dark">{{ $lang("extras.title") }}</h4>
      </div>
    </section>

    <section class="pb-5">
      <div class="container pb-3 table-responsive">
        <div class="row">
          <div class="col-lg-6 mb-4 mb-lg-0">
            <table class="table-secondary no-wrap even">
              <tr>
                <th colspan="2">{{ $lang("extras.table1.title") }}</th>
              </tr>
              <tr v-for="(item, i) of $lang('extras.table1.items')" :key="i">
                <td class="bg-secondary-50 fw-600 text-dark">
                  {{ item.label }}
                </td>
                <td>{{ item.value }}</td>
              </tr>
            </table>
          </div>
          <div class="col-lg-6">
            <table class="table-secondary no-wrap even">
              <tr>
                <th colspan="2">{{ $lang("extras.table2.title") }}</th>
              </tr>
              <tr v-for="(item, i) of $lang('extras.table2.items')" :key="i">
                <td class="bg-secondary-50 fw-600 text-dark">
                  {{ item.label }}
                </td>
                <td>{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>

    <div class="pt-3 pb-6 text-center">
      <button class="btn btn-primary px-5" @click="$router.push('/contact-us')">
        {{ $lang("button") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    prices: [
      { weeks: 1, a: 155, b: 76, c: 34 },
      { weeks: 2, a: 295, b: 152, c: 68 },
      { weeks: 3, a: 442, b: 228, c: 102 },
      { weeks: 4, a: 589, b: 304, c: 136 },
      { weeks: 5, a: 713, b: 379, c: 169 },
      { weeks: 6, a: 855, b: 454, c: 202 },
      { weeks: 7, a: 998, b: 529, c: 235 },
      { weeks: 8, a: 1080, b: 604, c: 268 },
      { weeks: 9, a: 1175, b: 678, c: 300 },
      { weeks: 10, a: 1305, b: 752, c: 332 },
      { weeks: 11, a: 1436, b: 826, c: 364 },
      { weeks: 12, a: 1566, b: 900, c: 396 },
    ],
    prices_private: [
      { hours: "1 to 1", a: 40, b: 35, c: 32, d: 10 },
      { hours: "1 to 2", a: 22, b: 20, c: 19, d: 6 },
      { hours: "1 to 3", a: 15, b: 13, c: 12, d: 4 },
    ],
    long_term_prices: [
      { weeks: 24, holidays: 8, months: 8, price: 2950 },
      { weeks: 30, holidays: 10, months: 10, price: 3540 },
      { weeks: 36, holidays: 14, months: 12, price: 4010 },
      { weeks: 42, holidays: 8, months: 12, price: 4375 },
      { weeks: 48, holidays: 2, months: 12, price: 4620 },
    ],
    dele_prices: [{ a: 390, b: 565, c: 695 }],
  }),
};
</script>
